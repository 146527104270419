var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ar-player" }, [
    _c(
      "div",
      { staticClass: "ar-player-actions" },
      [
        _c("icon-button", {
          staticClass: "ar-icon ar-icon__lg ar-player__play",
          class: { "ar-player__play--active": _vm.isPlaying },
          attrs: { id: "play", name: _vm.playBtnIcon },
          nativeOn: {
            click: function($event) {
              return _vm.playback.apply(null, arguments)
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ar-player-bar" },
      [
        _c("div", { staticClass: "ar-player__time" }, [
          _vm._v(_vm._s(_vm.playedTime))
        ]),
        _vm._v(" "),
        _c("line-control", {
          staticClass: "ar-player__progress",
          attrs: { "ref-id": "progress", percentage: _vm.progress },
          on: { "change-linehead": _vm._onUpdateProgress }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "ar-player__time" }, [
          _vm._v(_vm._s(_vm.duration))
        ]),
        _vm._v(" "),
        _c("volume-control", { on: { "change-volume": _vm._onChangeVolume } })
      ],
      1
    ),
    _vm._v(" "),
    _c("audio", { attrs: { id: _vm.playerUniqId, src: _vm.audioSource } })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7863735e", { render: render, staticRenderFns: staticRenderFns })
  }
}